<template>
  <div :key="influencerStatus">
    <!-- TODO: Refactor -->
    <b-row class="d-flex justify-content-between align-items-center">
      <h5 v-if="influencerStatus === 'approved'" class="ml-auto mr-auto text-success mt-1">{{ $t('campaigns.statusApproved') }}</h5>
      <h5 v-if="influencerStatus === 'rejected'" class="ml-auto mr-auto text-danger mt-1">{{ $t('campaigns.statusRejected') }}</h5>
      <h5 v-if="influencerStatus === 'onboarding'" class="ml-auto mr-auto text-primary mt-1">{{ $t('campaigns.onboarding') }}</h5>
      <h5 v-if="influencerStatus === 'trading'" class="ml-auto mr-auto text-warning mt-1">{{ $t('campaigns.tradingProfiles') }}</h5>
      <h5 v-if="influencerStatus === 'producing'" class="ml-auto mr-auto text-warning mt-1">{{ $t('campaigns.producing') }}</h5>
      <h5 v-if="influencerStatus === 'submitted'" class="ml-auto mr-auto text-primary mt-1">{{ $t('campaigns.submitted') }}</h5>
      <h5 v-if="influencerStatus === 'published'" class="ml-auto mr-auto text-success mt-1">{{ $t('campaigns.published') }}</h5>
    </b-row>
    <div v-if="influencerStatus === 'pending'" :id="uuid">
      <b-row class="d-flex justify-content-between align-items-center">
        <b-col><b-button class="blue-button-card mb-1" variant="blue-button-card" :disabled="disabledUpdateCampaign()" v-on:click="approvedButton(uuid)">{{ $t('campaigns.approveBtn') }}</b-button></b-col>
        <b-col><b-button class="red-button mb-1"  variant="red-button" :disabled="disabledUpdateCampaign()" v-on:click="rejectedButton(uuid)">{{ $t('campaigns.rejectBtn') }}</b-button></b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  VBToggle, BButton, BRow, BCol
} from 'bootstrap-vue'
import service from "@/services/campaign";
import { hasPermission } from '@/libs/utils/user';

export default {
  props: ['influencerStatus', 'influencerUuid'],
  components: {
    BButton,
    BRow,
    BCol,
  },
  directives: {
    'b-toggle': VBToggle
  },
  data () {
    return {
      hasPermission,
      status: this.influencerStatus,
      uuid: this.influencerUuid,
    }
  },
  created () {
  },
  methods: {
    approvedButton (id) {
      if (this.disabledUpdateCampaign()) return;
      service.influencerAction(this.$route.params.uuid, [id], 'approved')
        .then((response) => {
          this.statusApproved('success')

          this.$emit('update_influencer', response.results[0])
        })
        .catch(() => {
          this.errorMessage()
        })
    },
    rejectedButton (id) {
      if (this.disabledUpdateCampaign()) return;
      service.influencerAction(this.$route.params.uuid, [id], 'rejected')
        .then((response) => {
          
          this.statusApproved('warning')
          this.$emit('update_influencer', response.results[0])

        })
        .catch(() => {
          this.errorMessage()
        })
    },
    statusApproved (message_class) {
      let icon_text = message_class
      if (message_class === 'danger') icon_text = 'warning'

      this.$swal({
        title: this.$t('campaigns.influencerUpdate'),
        text: this.$t('campaigns.statusInfluencer'),
        icon: message_class,
        customClass: {
          confirmButton: `btn btn-${icon_text}`
        },
        heightAuto: false,
        buttonsStyling: false
      })
    },
    errorMessage () {
      this.$swal({
        title: this.$t('campaigns.errormessage'),
        text: this.$t('campaigns.tryAgainLater'),
        icon: 'danger',
        customClass: {
          confirmButton: 'btn btn-danger'
        },
        heightAuto: false,
        buttonsStyling: false
      })
    },
    disabledUpdateCampaign() {
      return !hasPermission('change_campaign')
    },
  },
}
</script>
<style scoped>
.blue-button-card {
  background-color: #0096fe !important;
  border: 0 !important;
  color: white !important;
  height: 100%;
} 
.blue-button-card:hover {
  background-color:rgb(77, 182, 249) !important;
  box-shadow: rgba(77, 182, 249, 0.3) 0px 7px 29px 0px !important;  
  color: white !important;
}
</style>

